//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Component untuk menampilkan Informasi Cart
 */
export default {
  name: 'InfoCart',
  props: {
    /**
     * @values object item cart
     */
    item: {
      type: Object,
      default() {
        return {
          totalPrice: 0,
          deduction: 0,
          totalOrder: 0,
        }
      },
    },
    /**
     * @values username merchant
     */
    merchantName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fromapp: this.$route.query.fromapp
    }
  },
  methods: {
    goToCart() {
      // if accessed from buyer app, dont go to cart
      if (this.$route.query.fromapp === 'true' || this.$route.query.fromapp === true) {
        this._helper_postMessageToBuyerApp('cart-floating-button')
      } else {
        this.goTo({ path: `/${this.merchantName}/cart`, query: this.$route.query });
      }
    }
  }
}
