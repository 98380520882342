//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Component Navbar Halaman home
 */
import { mapGetters, mapMutations } from 'vuex'
import ProductSortBottomSheet from '@/components/sheet/product-sort.vue'
import SellerContactDialog from '@/components/dialog/seller-contact.vue'

export default {
  name: 'NavbarHome',
  components: {
    ProductSortBottomSheet,
    SellerContactDialog,
  },
  data: () => ({
    slideGroup: 0,
    search: null,
    merchantName: null,
    merchantUsername: null,
    merchantLocation: null,
    merchantPhone: null,
    showSortBottomSheet: false,
    sortBy: null,
    contactDialogVisibility: false,
  }),
  computed: {
    ...mapGetters({
      getProducts: 'merchant/getProducts',
      getProfile: 'merchant/getProfile',
      merchant: 'merchant/getMerchantInfo',
    }),
    showMessage() {
      return encodeURI(`Hai ${this.getProfile && this.getProfile.User.name}!`)
    },
    titleSize() {
      return this.merchantName?.length < 20 ? 24 : this.merchantName?.length < 30 ? 18 : 16
    }
  },
  watch: {
    '$route.query'() {
      // Clear search query
      const { q, sortBy } = this.$router.currentRoute.query
      if (!q) {
        this.$refs.searchColumn.blur()
        this.search = null
      }
      this.sortBy = sortBy
    },
  },
  mounted() {
    // Set search query
    const { q, sortBy } = this.$router.currentRoute.query
    if (q) this.search = q
    if (sortBy) this.sortBy = sortBy
    this.handlingMerchantInfo()
  },
  methods: {
    ...mapMutations(['SET_EMPTY_STATE']),
    /**
     * Method untuk mendefinisikan merchant info
     *
     * @method handlingMerchantInfo
     */
    handlingMerchantInfo() {
      this.merchantName = (this.getProfile && this.getProfile.User.name) || '-'
      this.merchantUsername =
        (this.getProfile && this.getProfile.User.username) || ''
      this.merchantPhone =
        (this.getProfile && this.getProfile.User.phone) || '-'
      this.merchantLocation = this.handlingMerchantLocation()
    },
    /**
     * Method untuk menampilkan lokasi merchant
     *
     * @method handlingMerchantLocation
     */
    handlingMerchantLocation() {
      if (this.getProfile?.City?.name) {
        return this.getProfile?.City?.name
      }
      const splitAddress = this.getProfile?.address?.split(', ')
      const address = splitAddress?.filter(
        (item) =>
          item.startsWith('Kota') ||
          item.startsWith('Kab') ||
          item.includes('City')
      )
      return address && address[0] ? address[0] : ''
    },
    async handleSearch() {
      this.$refs.searchColumn.blur()
      this.slideGroup = 0
      if (this.search) {
        const query = Object.assign({}, this.$router.currentRoute.query)
        query.q = this.search
        await this.$router.push({ query })
      } else {
        const query = Object.assign({}, this.$router.currentRoute.query);
        if (query.q) delete query.q;
        await this.$router.push({ query })
      }
    },
    /**
     * Method untuk melakukan reset search
     *
     * @method resetSearch
     */
    async resetSearch() {
      this.$refs.searchColumn.blur()
      this.search = null
      const query = Object.assign({}, this.$router.currentRoute.query)
      delete query.q
      await this.$router.push({ query })
    },
    goToStoreInfo() {
      if (this.$route.query.fromapp === 'true' || this.$route.query.fromapp === true) {
        this._helper_postMessageToBuyerApp('store-info')
      } else {
        this.goTo(`/${this.merchantUsername}/info`);
      }
    },
    goToAskWhatsapp() {
      if (this.$route.query.fromapp === 'true' || this.$route.query.fromapp === true) {
        this._helper_postMessageToBuyerApp(`store-whatsapp-${this.convertPhoneNumberWithoutPlus(this.merchantPhone)}`)
      } else {
        if (!!window && !!window.open) {
          window.open(`https://wa.me/${this.convertPhoneNumberWithoutPlus(this.merchantPhone || '')}?text=${this.showMessage}`, '_blank')
        }
      }
    },
  },
}
