//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'SellerContactDialog',
  props: {
    isVisible: Boolean,
  },
  data() {
    return {
      isOpen: this.isVisible || false,
    }
  },
  watch: {
    isVisible() {
      this.isOpen = this.isVisible
    },
    isOpen() {
      if (this.isOpen === false) {
        this.$emit('onClose');
      }
    },
  },
  methods: {
    onWhatsappClick() {
      this.$emit('onWhatsappClick', false)
      this.isOpen = false
    },
    onInfoClick() {
      this.$emit('onInfoClick', true)
      this.isOpen = false
    },
  },
}
