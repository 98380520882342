var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-bar pt-4",class:{
    'header-collapsed': _vm.scrollPosition > 50,
  }},_vm._l((_vm.filterOpts),function(filterOpt){return _c('div',{key:filterOpt.id,staticClass:"d-flex flex-column align-center mb-4 pr-2"},[_c('div',{staticClass:"filter-bar__container",attrs:{"id":"product-cat-item-btn","data-testid":"product-cat-item-btn"},on:{"click":function($event){return _vm.setFilter(filterOpt)}}},[_c('div',{class:("filter-bar__icon " + (_vm.selectedFilter.id === filterOpt.id
            ? 'filter-bar__icon--selected'
            : '') + " d-flex flex-column justify-center align-center pa-2")},[_c('v-img',{staticClass:"filter-bar__icon--image",attrs:{"src":_vm.getDynamicStaticImg(filterOpt.icon),"width":"24","height":"24","contain":""}})],1),_vm._v(" "),_c('div',{class:("text-center text-ellipsis font-size--12 font-weight--400 mt-2 " + (_vm.selectedFilter.id === filterOpt.id
            ? 'text-color--primary font-weight--700'
            : 'text-color--grey')),style:(("" + (filterOpt.name.split(' ').length > 1
            ? 'word-break: initial; -webkit-line-clamp: 2'
            : 'word-break: break-all; -webkit-line-clamp: 1')))},[_vm._v("\n        "+_vm._s(filterOpt.name)+"\n      ")])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }