//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Component untuk item produk
 */
import BottomSheetCounter from '@/components/sheet/counter'

export default {
  name: 'ItemsProduct',
  components: {
    BottomSheetCounter,
  },
  props: {
    /**
     * @values username merchant
     */
    merchantName: {
      type: String,
      default: '',
    },
    coverageArea: {
      type: String,
      default: '',
    },
    /**
     * @values object item produk
     */
    item: {
      type: Object,
      default() {
        return {
          name: '',
          unit: {
            price: 0,
            discount_price: 0,
            name: '',
            ranked: null,
            productBought: null,
          },
        }
      },
    },
  },
  data() {
    return {
      isShowBuyingBottomSheet: false,
    }
  },
  computed: {
    discountPercentage() {
      return Number((1 - this.item.unit.discount_price / this.item.unit.price) * 100).toFixed(1)
    },
    productBought() {
      if (this.item.productBought > 999) {
        return '999+'
      }
      return this.item.productBought
    }
  },
  methods: {
    /**
     * Method untuk menampilkan image
     *
     * @method showImage
     * @param {object} item
     */
    showImage(item) {
      const idxMainImage = item.images.findIndex((el) => el.featured === true)
      return idxMainImage < 0
        ? item.images[0]?.url || '/img/default-product.svg'
        : item.images[idxMainImage]?.url || '/img/default-product.svg'
    },
    startBuy() {
      this.isShowBuyingBottomSheet = true;
      this.trackEvents({
        eventName: 'produk_klik_beli',
        eventCategory: 'order',
        eventLabel: 'product purchase click',
        eventValue: {
          productId: this.item.id
        },
        gtag: this.$gtag,
        path: this.$route.path,
      }, ['AF', 'GTM', 'FB'])
      this._helper_moengageTrackEvent('produk_klik_beli')
    },
    /**
     * Method untuk menuju ke detail produk
     *
     * @method goToProductDetail
     */
    goToProductDetail() {
      if (this.$route.query.fromapp === 'true' || this.$route.query.fromapp === true) {
        this._helper_postMessageToBuyerApp(`product-detail-${this.item && this.item.id}`)
      } else {
        this.$router.push({
          path: `/${this.merchantName}/${this.mapId('PRD-', this.item && this.item.id)}`,
          query: { ...this.$route.query, from: 'catalog' },
        })
      }
      this.trackEvents({
        eventName: 'produk_klik_detail',
        eventCategory: 'order',
        eventLabel: 'reorder',
        eventValue: {
          productId: this.item.id,
        },
        moengageContextId: this.item.id,
        gtag: this.$gtag,
        path: this.$route.path,
      }, ['AF', 'GTM', 'ME', 'FB'])
    },
    onItemButtonClicked() {
      if (this.item.unit && this.item.unit.isPriceAvailable) {
        this.startBuy();
      } else {
        this.goToProductDetail();
      }
    },
  },
}
