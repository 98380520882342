//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters, mapState, mapMutations } from 'vuex'
import Bugsnag from '@bugsnag/js'

import NavbarHome from '@/components/navbar/home'
import FilterBar from '@/components/filter-bar'
import ItemsProduct from '@/components/item/product'
import SkeletonGridListProduct from '@/components/skeleton/grid-list-products'
import EmptyState from '@/components/state/empty'
import SnackbarAlert from '@/components/snackbar-alert'
import InfoCart from '@/components/info/cart'

export const { BASE_URL } = process.env

export default {
  name: 'IndexMerchant',
  components: {
    NavbarHome,
    FilterBar,
    ItemsProduct,
    SkeletonGridListProduct,
    EmptyState,
    SnackbarAlert,
    InfoCart,
  },
  layout: 'home',
  async asyncData({ app, params, store, redirect }) {
    try {
      // set empty string for content component empty state
      await store.commit('SET_EMPTY_STATE', {
        title: 'Tidak Ada Produk',
        desc: 'Toko ini belum memiliki produk untuk dijual',
      })
      // check user if has order, default value is false
      const isHasOrder = await app.$cookies.get('gdio')
      if (!isHasOrder) store.commit('order/SET_IS_ORDER', false)

      // call API for hit data merchant, counter visitor, and categories
      const { slug } = params
      await store.dispatch('merchant/getDataMerchant', { slug })

      const merchantInfo = store.getters['merchant/getMerchantInfo']
      if (!merchantInfo.is_domain_active) {
        await store.commit('SET_EMPTY_STATE', {
          title: 'Toko Tutup',
          desc: 'Toko ini sedang tidak beroperasi, silakan hubungi penjual.',
        })
        return
      }

      await store.dispatch('merchant/counterVisitor', { slug })
      await store.dispatch('merchant/getDataCategory', { slug })
      await store.dispatch('merchant/getDataPaymentMethod', { slug })

      // check if merchant not found, redirect to landing page
      const res = await store.getters['merchant/getProfile']
      if (!res) redirect('/')
    } catch (error) {
      const err = error.response
      if (err) {
        app.$snackbar.showMessage({
          isShow: true,
          text: err?.data?.message || 'Unknown Error',
          registered: 'P-S-ASD',
          color: 'error',
        })
      } else {
        app.$snackbar.showMessage({
          isShow: true,
          text: error.message,
          registered: 'P-S-ASD',
          color: 'error',
        })
      }
      Bugsnag.notify(new Error(error))
    }
  },
  data() {
    return {
      limit: 10,
      offset: 0,
      currentFilterName: '',
      isLoadMore: false,
    }
  },
  head() {
    return {
      title:
        this.getMerchantInfo?.User?.name ||
        `${this.$config.APP_NAME} - ${this.$config.BRAND_TAGLINE}`,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content:
            this.getMerchantInfo?.User?.name ||
            `${this.$config.APP_NAME} - ${this.$config.BRAND_TAGLINE}`,
        },
        {
          hid: 'description',
          name: 'description',
          content: this.showAddress(this.getMerchantInfo),
        },
        { ogType: 'website' },
        { ogUrl: `${BASE_URL}/${this.getMerchantInfo?.User?.username}` },
        {
          ogTitle:
            this.getMerchantInfo?.User?.name ||
            `${this.$config.APP_NAME} - ${this.$config.BRAND_TAGLINE}`,
        },
        {
          ogDescription: this.showAddress(this.getMerchantInfo),
        },
        {
          ogImage:
            this.getMerchantInfo?.profile_picture ||
            `${BASE_URL}/${this.$config.APP_NAME}.png`,
          alt:
            this.getMerchantInfo?.User?.name ||
            `${this.$config.APP_NAME} - ${this.$config.BRAND_TAGLINE}`,
          type: 'image/png',
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content:
            this.getMerchantInfo?.User?.name ||
            `${this.$config.APP_NAME} - ${this.$config.BRAND_TAGLINE}`,
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content:
            this.getMerchantInfo?.User?.name ||
            `${this.$config.APP_NAME} - ${this.$config.BRAND_TAGLINE}`,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.showAddress(this.getMerchantInfo),
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `${BASE_URL}/${this.getMerchantInfo?.User?.username}`,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content:
            this.getMerchantInfo?.profile_picture ||
            `${BASE_URL}/${this.$config.APP_NAME}.png`,
        },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        {
          hid: 'og:image:type',
          property: 'og:image:type',
          content: 'image/png',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      merchantName: 'merchant/getMerchantName',
      getMerchantInfo: 'merchant/getMerchantInfo',
      getCart: 'merchant/getCart',
      getProducts: 'merchant/getProducts',
      getLoading: 'getLoading',
    }),
    ...mapState('merchant', ['products', 'category']),
    getCart() {
      return this.$store.getters['merchant/getCart']
    },
    summary() {
      return this.getCheckoutData(this.getCart.items, {})
    },
    rows() {
      return this.products?.rows || []
    }
  },
  // watch changes to route query to refetch data products
  watch: {
    '$route.query'() {
      // reset limit
      this.limit = 10
      this.offset = 0
      this.fetchProducts()
    },
  },
  async mounted() {
    if (process.browser) {
      await this.fetchProducts()
      await this.$items.initCart({})
    }

    this.$root.$on('reset-search', () => {
      this.name = ''
    })
    if (!!window && !!window.addEventListener) {
      window.addEventListener('scroll', this.handleOnScroll)
    }

    this.$gtag.pageview(this.$route.path)
    this.$gtag.config({ user_id: await this.getBuyerId() })
    this.$gtag.set('user_properties', {
      coverage_area_id: await this.getCoverageAreaId(),
      cm_user_id: await this.getBuyerId(),
    })
  },
  destroyed() {
    if (!!window && !!window.removeEventListener) {
      window.removeEventListener('scroll', this.handleOnScroll)
    }
  },
  methods: {
    ...mapMutations(['SET_EMPTY_STATE']),
    async fetchProducts() {
      const username = await this.$route.params?.slug
      const payload = {
        username,
        limit: this.limit,
        offset: this.offset,
      }
      const {
        q,
        filter,
        category,
        sortBy,
        orderBy,
      } = this.$router.currentRoute.query

      if (q) payload.name = q
      if (filter === 'promo') payload.promo_active = true
      if (filter === 'new') payload.is_new = true
      if (category) payload.product_category_id = `PRC-${category}`
      if (sortBy) payload.sort_by = sortBy
      if (orderBy) payload.order_by = orderBy

      await this.$items.getProducts(payload)

      const products = await this.getProducts
      if (products?.length === 0) {
        if (q) {
          this.$store.commit('SET_EMPTY_STATE', {
            title: 'Hasil Pencarian\nTidak Ditemukan',
            desc: 'Hasil pencarian yang Anda cari tidak ditemukan,\nsilahkan lihat produk yang telah tersedia lainnya.',
          })
        }
        if (category) {
          this.$store.commit('SET_EMPTY_STATE', {
            title: 'Tidak Ada Produk',
            desc: `Produk ${q ? `"${q}"` : ''} tidak ditemukan di ${
              this.currentFilterName
            }`,
          })
        }
      }
    },
    async handleOnScroll() {
      if (this.isLoadMore) return
      if (
        !!window && window.innerHeight + Math.ceil(window.pageYOffset) >=
        document.body.scrollHeight
      ) {
        if (this.offset + this.limit > this.products?.count) return
        this.isLoadMore = true
        this.offset += 10
        await this.fetchProducts()
        this.isLoadMore = false
      }
    },
  },
}
