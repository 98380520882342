import { render, staticRenderFns } from "./product-sort.vue?vue&type=template&id=95e55f8c&scoped=true&"
import script from "./product-sort.vue?vue&type=script&lang=js&"
export * from "./product-sort.vue?vue&type=script&lang=js&"
import style0 from "./product-sort.vue?vue&type=style&index=0&id=95e55f8c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95e55f8c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBottomSheet,VBtn,VIcon,VSheet})
