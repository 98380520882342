import { render, staticRenderFns } from "./index.vue?vue&type=template&id=f17bda74&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=f17bda74&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f17bda74",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SnackbarAlert: require('/home/deploy/JooalanWeb/components/snackbar-alert.vue').default,NavbarHome: require('/home/deploy/JooalanWeb/components/navbar/home.vue').default,FilterBar: require('/home/deploy/JooalanWeb/components/filter-bar/index.vue').default,InfoCart: require('/home/deploy/JooalanWeb/components/info/cart.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})
