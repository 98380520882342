//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Component Snackbar Alert
 */
export default {
  name: 'SnackbarAlert',
  computed: {
    initData() {
      return { ...this.$store.getters.getDataMessage }
    },
    isCrediMart() {
      return this.$config.APP_NAME === 'credimart'
    },
    snackbarColor() {
      if (this.isCrediMart) return ''
      return this.initData?.color
    }
  },
  watch: {
    initData() {
      if (this.initData.isShow) {
        setTimeout(() => {
          this.$store.commit('SET_DATA_MESSAGE', {
            ...this.initData,
            isShow: false,
            text: '',
          })
        }, this.initData.timeout || 8000)
      }
    }
  },
  methods: {
    onActionBtnClick() {
      this.initData?.onActionBtnClick()
    }
  },
}
