//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Component Empty State
 */
import { mapGetters } from 'vuex'

export default {
  name: 'EmptyState',
  props: {
    /**
     * @values array items produk
     */
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    category: {
      type: Array,
      default: () => {
        return []
      },
    },
    imageUrl: {
      type: String,
      default: () => '/img/empty-order.svg'
    },
  },
  data() {
    return {
      showNoProduct: false,
    }
  },
  computed: {
    ...mapGetters({
      getLoading: 'getLoading',
      getEmptyState: 'getEmptyState',
    }),
  },
  mounted() {
    setTimeout(() => {
      this.showNoProduct = true
    }, 400)
  },
}
