//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Component Bottom Sheet Counter Product
 */
export default {
  name: 'BottomSheetCounter',
  props: {
    // @values object item produk
    itemData: {
      type: Object,
      default() {
        return {
          listUnit: { unit: '' },
          unit: { discount_price: 0, price: 0, name: '' },
        }
      },
    },
    // @values true/false show bottom sheet
    value: { type: Boolean, default: false },
    // @values true/false jika dari detail page
    detailPage: { type: Boolean, default: false },
    // @values username merchant
    merchantName: { type: String, default: '' },
  },
  data() {
    const unit = this.itemData?.unit
    const totalPrice =
      unit?.discount_price > 0 ? unit?.discount_price : unit?.price

    return {
      hasQuantity: false,
      counter: 0,
      selectedUnit: unit ? unit.name : '',
      // stock: unit ? (unit.stock - 1) : 0,
      totalPrice,
    }
  },
  computed: {
    show: {
      get() {
        if (this.value) this.init()
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    /**
     * Method untuk inisialisasi qty
     *
     * @method init
     * @success : inisialisasi qty = 1
     */
    init() {
      this.counter = 1
    },
    /**
     * Update selected unit price
     */
    updatePrice() {
      this.counter = parseInt(this.counter || 0, 10)
      const currentUnit = this.itemData.allUnits.find(
        (el) => el.name === this.selectedUnit && this.counter >= el.min_quantity
      )

      if (currentUnit) {
        this.totalPrice =
          currentUnit.discount_price > 0
            ? currentUnit.discount_price
            : currentUnit.price
      }
    },
    // updateStock() {
    //   const currentUnit = this.itemData.allUnits.find(
    //     (el) => el.name === this.selectedUnit
    //   )

    //   if (currentUnit) {
    //     this.stock = currentUnit.stock
    //     // reset counter
    //     this.counter = 0
    //   }
    // },
    /**
     * Method untuk menambah qty keranjang item produk
     *
     * @method plus
     * @success : menambah qty keranjang item produk
     */
    plus() {
      // if (this.stock === 0) return
      this.counter = parseInt(this.counter) + 1
      // this.stock = parseInt(this.stock) - 1
      // TODO: check latest stock ke server
      this.updatePrice()
    },
    /**
     * Method untuk mengurangi qty keranjang item produk
     *
     * @method minus
     * @success : mengurangi qty keranjang item produk
     */
    minus() {
      if (this.counter > 0) {
        this.counter -= 1
        // this.stock += 1
      }
      // TODO: check latest stock ke server
      this.updatePrice()
    },
    /**
     * Method untuk menambahkan item ke keranjang
     *
     * @method addToCart
     * @success : menambahkan ke item keranjang dan mengupdate list produk
     */
    async addToCart() {
      const resellerAreaData = await this.$cookies.get('gdb') ?? {}
      const merchantProfile = await this.$cookies.get('gdmp') ?? {}
      if (merchantProfile?.username !== this.$config.RESELLER_PATH) {
        this.$items.addItemToCart(this.itemData, this.counter || 0, this.selectedUnit, false)
      } else {
        if (this.itemData?.coverageAreaIds?.includes(resellerAreaData.coverageAreaId ?? '') || this.itemData?.coverageAreaId === (resellerAreaData.coverageAreaId ?? '')) {
          this.$items.addItemToCart(this.itemData, this.counter || 0, this.selectedUnit, false)
        } else {
          this.$snackbar.showMessage({ isShow: true, text: 'Produk di luar area pengiriman', registered: 'C-ATC' })
        }
      }
      this.show = false
      if (this.detailPage) {
        this.$emit('addToCartClick')
      }

      if (this.$route.query.fromapp === 'true' || this.$route.query.fromapp === true) {
        this._helper_postMessageToBuyerApp('cart-product-change');
      }

      this.trackEvents({
        eventName: 'produk_atc',
        eventCategory: 'order',
        eventLabel: 'add to cart',
        eventValue: { item: this.itemData, counter: this.counter },
        gtag: this.$gtag,
        path: this.$route.path,
      }, ['AF', 'GTM', 'ME', 'FB'])
    },
    /**
     * Method untuk tutup bottom sheet
     *
     * @method closeDialog
     * @success : tutup bottom sheet dan reset counter
     */
    closeDialog() {
      this.$emit('input', false)
      this.counter = 1
      this.selectedUnit = this.itemData.unit.name
      const unit = this.itemData?.unit
      const totalPrice =
        unit?.discount_price > 0 ? unit?.discount_price : unit?.price
      this.totalPrice = totalPrice
    },
  },
}
