//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex'
import Bugsnag from '@bugsnag/js'

export default {
  name: 'FilterBar',
  data: () => ({
    scrollPosition: 0,
    filter: {
      SEMUA: {
        id: 'all',
        name: 'Semua',
        icon: '/img/category/semua.svg',
      },
      PROMO: {
        id: 'promo',
        name: 'Promo',
        icon: '/img/category/promo.svg',
      },
      BARU: {
        id: 'new',
        name: 'Baru',
        icon: '/img/category/baru.svg',
      },
    },
    defaultFilterOpts: [],
    filterOpts: [],
    selectedFilter: null,
  }),
  computed: {
    ...mapGetters({
      getProducts: 'merchant/getProducts',
      getCategory: 'merchant/getCategory',
    }),
  },
  watch: {
    '$route.query'() {
      this.handleActiveFilter()
    },
  },
  mounted() {
    this.defaultFilterOpts = [
      this.filter.SEMUA,
      this.filter.PROMO,
      this.filter.BARU,
    ]
    this.filterOpts = this.defaultFilterOpts
    this.selectedFilter = this.filter.SEMUA

    if (this.getCategory && this.getCategory.length > 0) {
      this.filterOpts = [
        ...this.filterOpts,
        ...this.getCategory.filter((category) => category.active),
      ]
    }

    this.handleActiveFilter()
    if (!!window && !!window.addEventListener) {
      window.addEventListener('scroll', this.handleOnScroll)
    }
  },
  destroyed() {
    if (!!window && !!window.removeEventListener) {
      window.removeEventListener('scroll', this.handleOnScroll)
    }
  },
  async beforeMount() {
    await this.checkAnyProductsPromo()
    await this.CheckAnyNewProducts()
  },
  methods: {
    ...mapMutations(['SET_EMPTY_STATE']),
    handleOnScroll() {
      if (!!window && !!window.scrollY) {
        this.scrollPosition = window.scrollY
      }
    },
    handleActiveFilter() {
      const { filter, category } = this.$router.currentRoute.query
      let query
      if (filter) query = filter
      if (category) query = `PRC-${category}`

      let activeFilter = this.filter.SEMUA
      if (query) {
        activeFilter = this.filterOpts.find((f) => f.id === query)
      }
      this.setFilter(activeFilter)
    },
    async checkAnyProductsPromo() {
      try {
        const username = await this.$route.params?.slug
        const data = await this.$axios.$get('/guest/seller-product/', {
          params: { username, limit: 1, promo_active: true },
        })

        if (data && data?.product && data?.product?.count === 0) {
          this.filterOpts = this.filterOpts?.filter(
            (opt) => opt.id !== this.filter.PROMO.id
          )
        }
      } catch (error) {
        if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
          Bugsnag.notify(new Error(error))
        }
      }
    },
    async CheckAnyNewProducts() {
      try {
        const username = await this.$route.params?.slug
        const data = await this.$axios.$get('/guest/seller-product/', {
          params: { username, limit: 1, is_new: true },
        })

        if (data && data?.product && data?.product?.count === 0) {
          this.filterOpts = this.filterOpts?.filter(
            (opt) => opt.id !== this.filter.BARU.id
          )
        }
      } catch (error) {
        if (error && error.response && error.response.status !== 400 && error.response.status !== 520) {
          Bugsnag.notify(new Error(error))
        }
      }
    },
    setFilter(item) {
      if (this.selectedFilter === item) return
      this.selectedFilter = item
      this.$emit('currentFilterName', item?.name)

      const query = Object.assign({}, this.$router.currentRoute.query)

      if (this.selectedFilter === this.filter.SEMUA) {
        delete query.filter
        delete query.category
      }

      if (this.selectedFilter === this.filter.PROMO) {
        query.filter = 'promo'
        delete query.category
      }

      if (this.selectedFilter === this.filter.BARU) {
        query.filter = 'new'
        delete query.category
      }

      if (!this.defaultFilterOpts.includes(this.selectedFilter)) {
        query.category = this.mapId('PRC-', item.id)
        delete query.filter
      }

      this.$router.push({ query })
    },
  },
}
