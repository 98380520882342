//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ProductSortBottomSheet',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    sortOpts: {
      type: Array,
      default() {
        return [
          { id: 1, orderBy: 'newest', sortBy: 'desc', title: 'Terbaru' },
          { id: 2, orderBy: 'price', sortBy: 'asc', title: 'Harga Terendah' },
          { id: 3, orderBy: 'price', sortBy: 'desc', title: 'Harga Tertinggi' },
          { id: 4, orderBy: 'name', sortBy: 'asc', title: 'Urutkan dari A ke Z' },
        ]
      }
    },
  },
  data: () => ({
    selected: null,
  }),
  computed: {
    sheet: {
      get() {
        return this.visible
      },
    },
  },
  watch: {
    '$route.query'() {
      this.handleActiveSort()
    },
  },
  mounted() {
    this.handleActiveSort()
  },
  methods: {
    handleActiveSort() {
      const { sortBy, orderBy } = this.$router.currentRoute.query
      if (sortBy && orderBy) {
        this.selected = this.sortOpts.find(
          (opt) => opt.sortBy === sortBy && opt.orderBy === orderBy
        )
      } else this.handleReset()
    },
    handleSelect(value) {
      this.selected = value
    },
    handleReset() {
      this.selected = null
    },
    onSave() {
      const query = Object.assign({}, this.$router.currentRoute.query)

      if (this.selected) {
        query.sortBy = this.selected.sortBy
        query.orderBy = this.selected.orderBy
      } else {
        delete query.orderBy
        delete query.sortBy
      }

      this.$router.push({ query })
      this.$emit('onClose')
      if (!!window && !!window.scrollTo) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }

      this.trackEvents({
        eventName: 'pesanan_sort',
        eventCategory: 'order',
        eventLabel: 'sort order',
        gtag: this.$gtag,
        path: this.$route.path,
      }, ['AF', 'GTM', 'FB'])
      this._helper_moengageTrackEvent('pesanan_sort')
    },
    onClickOutside() {
      this.$emit('onClose')
      this.handleActiveSort()
    },
  },
}
